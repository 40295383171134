import { graphql, Link, useStaticQuery } from 'gatsby'
import React from 'react'
import Arrow from './Arrow'

import * as projectStyles from '../styles/projectGrid.module.scss'

function ProjectGrid() {

  const projects = useStaticQuery(graphql`
    query{
      allContentfulProject(
        filter: {
          active: {
            eq: true
          }
        }
        sort: {
          fields: startDate
        }
        limit: 3
      ){
          edges{
              node{
                  slug
                  title
                  screenshot{
                    file{
                      url
                    }
                  }
              }
          }
      }
    }
  `);
    //console.log(projects.allContentfulProject.edges);
    return (
      <section className="blue">
        <h2 className="mt-0">What I'm currently up to</h2>
        <div className={ projectStyles.row }>
          { projects.allContentfulProject.edges.map( (project) => {
            return <Project key={ project.node.slug } data={ project }/>
          }) }
        </div>
        <Link to="/projects">see more <Arrow direction="right"/></Link>
      </section>
    )
}
export default ProjectGrid

export const Project = (props)=>{
  return(
    <Link className={ projectStyles.project } to={ '/projects/' + props.data.node.slug }>
      <div className={ projectStyles.screenshot } style={ { backgroundImage: 'url(' + props.data.node.screenshot.file.url +')'}}></div>
      <h5>{ props.data.node.title }</h5>
    </Link>
  );
}