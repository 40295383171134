import * as React from "react"
import { Link } from "gatsby"

import Layout from "../components/Layout"
import ProjectGrid from "../components/ProjectGrid"
import Skills from "../components/Skills"
import Arrow from "../components/Arrow"
import { Helmet } from "react-helmet"


const IndexPage = () => {
  return (
    <>
      <Helmet title="Welcome! | MM-develops"/>
      <h1 style={{textAlign: "center"}}>Site under maintenance</h1>
      <i style={{textAlign: "center", width: "100%", display:"inline-block"}}>I'll be back!</i>
      {/*<Layout>
        <section>
          <h1 className="mb-0">Welcome!!</h1>
          <h4 className="mb-0 mt-0">My name is Monica Meraz</h4>
          <h5 className="mt-0">I am a creative web developer and this is my portfolio / blog. </h5>
          <Arrow direction="down"/>

        </section>
        <ProjectGrid/>
        <section className="row">
          <div className="half"></div>
          <div className="half">
            <h3>A little bit about me</h3>
            <p>Hello! I'm a pathologically curios developer. I love learning new things and sharing everything I find interesting. I have three years of professional experience developing frontend and I'm looking into becoming a fullstack developer.</p>
            <Link to="/about">Read more<Arrow direction="right"/></Link>
          </div>
        </section>
        <Skills/>
      </Layout>*/}
    </>
  )
}

export default IndexPage
